import { FaStar } from "react-icons/fa";
import Image from "next/image";

const ReviewSection: React.FC = () => {
  const stars = Array.from({ length: 5 }, (_, index) => (
    <FaStar key={index} className="text-primary" />
  ));
  const avatars = [
    { id: "avatar1", src: "/images/avatar/1.svg", alt: "Avatar 1" },
    { id: "avatar2", src: "/images/avatar/2.svg", alt: "Avatar 2" },
    { id: "avatar3", src: "/images/avatar/3.svg", alt: "Avatar 3" },
    { id: "avatar4", src: "/images/avatar/4.svg", alt: "Avatar 4" },
    { id: "avatar5", src: "/images/avatar/2.svg", alt: "Avatar 5" },
  ];

  return (
    <div className="lg:w-1/2 px-8">
      <div className="bg-white w-full py-6 text-center mt-20 p-4 shadow-md rounded-2xl">
        <div className="flex justify-center items-center my-4 mb-2 text-2xl gap-2">
          {stars}
        </div>
        <p className="font-semibold">Lorem ipsum dolor sit amet consectetur.</p>
        <div className="flex flex-wrap justify-center space-x-2 mt-4 mx-4">
          {avatars.map((avatar, index) => (
            <Image
              key={avatar.id}
              src={avatar.src}
              alt={avatar.alt}
              width={40}
              height={40}
              className="rounded-full border-2 border-gray-200"
              priority
            />
          ))}
        </div>
        <button className="mt-4 bg-black text-primary py-2 px-6 rounded-xl">
          Kelas Terbaik Disini
        </button>
      </div>
    </div>
  );
};

export default ReviewSection;
