"use client";
import React, { useState } from "react";
import HeroSection from "./components/sections/hero";
import ReviewSection from "./components/sections/review";
import MainCard from "./components/cards/Main";
import BenefitSection from "./components/sections/benefit";
import AdditionalInformation from "./components/sections/additional-Information-landing";
import Reasons from "./components/sections/reason";
import { bgLanding } from "./data/landing";

const LandingPage: React.FC = () => {
  return (
    <div className="flex flex-col items-center mt-10 mx-6 lg:mx-24 rounded-lg ">
      {/* Hero Section */}
      <HeroSection
        url={bgLanding.background1ImageSrc}
        text="Saatnya Latih Skill Kamu"
      />

      {/* Review Section */}
      <ReviewSection />

      {/* Main Card */}
      <MainCard />

      {/* Benefit Section */}
      <BenefitSection />

      {/* Additional Information */}
      <AdditionalInformation />

      {/* Reasons Section */}
      <Reasons />
    </div>
  );
};

export default LandingPage;
