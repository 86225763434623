export const reasonsData = [
  {
    title: "Komprehensif",
    description:
      "Lorem ipsum dolor sit amet consectetur. Bibendum aliquam tempor faucibus justo a vitae sed. Leo sit ullamcorper nunc dolor.",
  },
  {
    title: "Profesional",
    description:
      "Quisque velit nisi, pretium ut lacinia in, elementum id enim. Curabitur non nulla sit amet nisl.",
  },
  {
    title: "Terpercaya",
    description:
      "Nulla porttitor accumsan tincidunt. Cras ultricies ligula sed magna dictum porta.",
  },
  {
    title: "Inovatif",
    description:
      "Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.",
  },
];
