import React from "react";

const SectionContent: React.FC = () => {
  return (
    <>
      <h3 className="text-center text-sm uppercase tracking-wide mb-4">
        ALASAN UTAMA
      </h3>
      <h2 className="text-center text-3xl mb-2">Kenapa Harus Pilih</h2>
      <h2 className="text-center text-2xl font-semibold mb-6">Garis Prtama?</h2>
    </>
  );
};

export default SectionContent;
