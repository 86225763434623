import React from "react";

interface CourseInformation {
  id: number;
  name: string;
  color: "primary" | "secondary";
}

const courses: CourseInformation[] = [
  { id: 1, name: "Kelas Desain", color: "primary" },
  { id: 2, name: "AI-Art", color: "secondary" },
  { id: 3, name: "Animasi", color: "primary" },
  { id: 4, name: "Microstok", color: "secondary" },
];

// Fungsi utilitas untuk menentukan kelas warna
const getClassNames = (color: "primary" | "secondary") => {
  return {
    container: color === "primary" ? "bg-primary" : "bg-secondary",
    text: color === "primary" ? "text-primary" : "text-secondary",
  };
};

// Gunakan memo untuk menghindari re-rendering yang tidak perlu
const CourseCard: React.FC<CourseInformation> = ({ id, name, color }) => {
  const { container, text } = getClassNames(color);

  return (
    <div
      key={id}
      className={`flex items-center justify-center p-4 rounded-lg text-white ${container}`}
    >
      <div className="flex items-center space-x-2 w-1/2 lg:w-1/4">
        <span
          className={`bg-white ${text} font-bold rounded-full h-10 w-10 flex items-center justify-center`}
        >
          {id}
        </span>
        <span className="text-lg font-medium">{name}</span>
      </div>
    </div>
  );
};

const AdditionalInformation: React.FC = () => {
  return (
    <div className="flex flex-col w-full items-center justify-center">
      <h2 className="text-center text-xl font-semibold mb-6">
        Sebagai Solutif Untuk Kamu Mengembangkan Bakat
      </h2>

      <div className="space-y-4 w-full md:w-1/2 lg:w-full xl:w-1/2">
        {courses.map((course) => (
          <CourseCard key={course.id} {...course} />
        ))}
      </div>
    </div>
  );
};

export default AdditionalInformation;
