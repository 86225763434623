import React from "react";
import BenefitItem from "./BenefitItem";
import Link from "next/link";

const BenefitSection: React.FC = () => {
  const benefits = [
    "Mentor Kompeten",
    "Fleksibilitas Belajar",
    "Bersertifikat",
    "Akses Selamanya",
    "Mentoring By Mentor",
    "No Storage Needed",
    "Challenge Mingguan",
    "Materi Updates",
  ];

  return (
    <div className="bg-font text-white shadow-lg rounded-lg p-6 w-full my-14">
      <div className="flex justify-between items-center mb-4 ">
        <h1 className="text-2xl sm:text-4xl md:text-7xl font-semibold mr-6">
          Benefit
        </h1>
        <Link
          href="/courses"
          className="bg-primary text-white text-center text-sm px-4 py-2 rounded-full hover:bg-yellow-600 transition"
        >
          Lihat Semua Kelas
        </Link>
      </div>

      <ul className="flex flex-wrap gap-4">
        {benefits.map((benefit, index) => (
          <BenefitItem key={index} text={benefit} />
        ))}
      </ul>
    </div>
  );
};

export default BenefitSection;
