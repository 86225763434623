import Image from "next/image";

interface HeroSectionProps {
  url: string;
  text: string;
  borderRadius?: string;
  height?: string;
  marginText?: string;
}

const HeroSection: React.FC<HeroSectionProps> = ({
  url,
  text,
  borderRadius = " rounded-t-lg",
  height = "h-[600px]",
  marginText = "-mt-80",
}) => {
  return (
    <>
      <div
        className={`relative w-full mt-10 mx-6 lg:mx-24 ${borderRadius} ${height} -z-10`}
      >
        <Image
          src={url}
          alt={text}
          fill={true}
          style={{ objectFit: "cover" }}
          priority
          // Ensure the image covers the entire area
          className="rounded-b-lg " // Optional: Apply rounded corners
        />
      </div>
      <div
        className={`text-center ${marginText}  p-4 text-2xl font-semibold text-white w-1/2 z-8`}
      >
        {text}
      </div>
    </>
  );
};

export default HeroSection;
