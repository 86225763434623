import React, { useMemo, useState } from "react";
import Slider from "react-slick";
import SectionContent from "./Content";
import SlideItem from "./SliderItem";
import { NextArrow, PrevArrow } from "../../buttons/ArrowButtons";
import Image from "next/image";
import { reasonsData } from "@/app/data/reason";

const Reasons: React.FC = () => {
  const settings = useMemo(
    () => ({
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      appendDots: (dots: React.ReactNode) => (
        <div>
          <ul className="flex justify-center mt-8 space-x-2">{dots}</ul>
        </div>
      ),
      customPaging: (i: number) => (
        <div className="w-3 h-3 -mt-8 rounded-full cursor-pointer bg-gray-400" />
      ),
    }),
    []
  );
  return (
    <section className="relative bg-cover h-[550px] text-white shadow-lg rounded-lg p-6 max-w-sm md:max-w-full my-14">
      {/* Mengganti <img> dengan <Image> untuk optimasi */}
      <Image
        src="/images/background/landing2.webp"
        alt="Background"
        fill
        style={{ objectFit: "cover" }}
        className="rounded-lg"
      />
      <SectionContent />

      <Slider {...settings}>
        {reasonsData.map((slide, index) => (
          <SlideItem
            key={index}
            title={slide.title}
            description={slide.description}
          />
        ))}
      </Slider>
    </section>
  );
};

export default Reasons;
