import Image from "next/image";
import React from "react";

const MainCard: React.FC = () => {
  return (
    <div className="bg-gray-100 shadow-lg rounded-lg p-6 max-w-2xl mx-auto my-14">
      <h1 className="text-xl text-center mb-4 md:font-bold md:text-2xl mx-20">
        Platform Yang Tepat Untuk Upgrade Skill Kamu
      </h1>
      <div className="flex justify-center mb-4">
        <Image
          src="/images/thumbnail/1.webp"
          alt="Course Image"
          sizes="(100vw, 100vh)"
          width={500} // Adjust this to the actual width of the image
          height={300} // Adjust this to the actual height of the image
          className="rounded-lg object-contain  border-8 border-font"
          priority
        />
      </div>
      <p className="text-center text-gray-700 text-md lg:text-2xl mx-10">
        Bareng Garis Pratama
      </p>
      <p className="text-center text-gray-700 font-semibold text-2xl lg:text-4xl">
        Siap Temenin
      </p>
      <p className="text-center text-gray-700 font-semibold text-2xl lg:text-4xl">
        Kamu Naik Level.
      </p>
    </div>
  );
};

export default MainCard;
