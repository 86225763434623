import React from "react";
import { PiStarFourFill } from "react-icons/pi"; // Pastikan Anda mengimpor icon yang benar

interface BenefitItemProps {
  text: string;
}

const BenefitItem: React.FC<BenefitItemProps> = ({ text }) => {
  return (
    <li className="flex items-center w-full sm:w-[calc(50%-1rem)] lg:w-[calc(33.333%-1rem)]">
      <PiStarFourFill className="text-primary text-4xl mr-4" />
      <span className="text-2xl">{text}</span>
    </li>
  );
};

export default BenefitItem;
