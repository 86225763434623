import React from "react";

interface SlideItemProps {
  title: string;
  description: string;
}

const SlideItem: React.FC<SlideItemProps> = ({ title, description }) => {
  return (
    <div className="h-80 bg-black bg-opacity-50 backdrop-blur-lg p-6 rounded-lg flex flex-col justify-center items-center text-center">
      <h3 className="text-xl font-semibold mt-4">{title}</h3>
      <p className="text-gray-300 mt-4">{description}</p>
    </div>
  );
};

export default SlideItem;
