import React from "react";
import {
  MdOutlineArrowForwardIos,
  MdOutlineArrowBackIosNew,
} from "react-icons/md";

interface ArrowProps {
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

export const NextArrow: React.FC<ArrowProps> = ({ onClick }) => {
  return (
    <div
      className="text-white text-sm lg:text-4xl z-10 absolute -right-1 top-1/2 transform -translate-y-1/2 cursor-pointer"
      onClick={onClick}
    >
      <MdOutlineArrowForwardIos className="text-3xl" />
    </div>
  );
};

export const PrevArrow: React.FC<ArrowProps> = ({ onClick }) => {
  return (
    <div
      className="text-white text-sm lg:text-4xl z-10 absolute -left-1 top-1/2 transform -translate-y-1/2 cursor-pointer"
      onClick={onClick}
    >
      <MdOutlineArrowBackIosNew className="text-3xl" />
    </div>
  );
};
